import "../assets/css/Footer.css";

export default function Footer() {
  return (
    <div>
      <p className="copywrite">
        © jckrft 2021
      </p>
    </div>
  );
}
